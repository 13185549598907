<template>
  <div id="staff-add">
    <v-container>
      <div class="operate d-flex justify-end">
        <div class="back">
          <v-btn
            dark
            color="primary"
            class="add align-self-center"
            @click="$router.go(-1)"
          >
            <v-icon dark> mdi-backburger </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="content">
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="data.account"
            :rules="accountRules"
            label="请填写账号"
            required
            :error-messages="errorMessages.account"
          ></v-text-field>

          <v-text-field
            v-model="data.password"
            :rules="passwordRules"
            label="请填写密码"
            type="password"
            :required="!id"
          ></v-text-field>
          <v-text-field v-model="data.name" label="请填写姓名"></v-text-field>
          <div class="d-flex justify-end">
            <v-btn class="px-16" color="success" x-large @click="save">
              保存
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    async save() {
      if (!this.$refs.form.validate()) return;

      (!this.id
        ? this.$shareyueApi.addStaff(this.data)
        : this.$shareyueApi.editStaff(this.id, this.data)
      )
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          if (!error) return;
          for (var key in error) {
            if (key in this.errorMessages) {
              this.errorMessages[key] = error[key];
            }
          }
        });
    },
    async load() {
      this.id = this.$route.query.id;
      if (!this.id) return;
      await this.$shareyueApi.getStaff(this.id).then((data) => {
        Object.assign(
          this.data,
          (({ account, name }) => ({
            account,
            name,
          }))(data)
        );
      });
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      id: "",
      data: {
        account: "",
        password: "",
        name: "",
      },
      valid: true,
      errorMessages: {
        account: "",
      },
      accountRules: [(v) => !!v || "请填写账号"],
      passwordRules: [(v) => !!v || !!this.id || "请填写密码"],
    };
  },
};
</script>
<style lang="less" scoped>
</style>
